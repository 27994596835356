<template>
  <div id="app">
    <!-- <HelloWorld /> -->
    <!-- <textToImg /> -->
    <moblieVue v-if="isMoblie" />
    <zhihuVue v-else />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import textToImg from './components/textToImg.vue';
import zhihuVue from './components/zhihuVue.vue';
import moblieVue from './components/moblieVue.vue';


export default {
  name: 'App',
  data () {
    return {
      isMoblie: /Mobi|Android|iPhone/i.test(window.navigator.userAgent)
    }
  },
  components: {
    // HelloWorld,
    // textToImg,
    zhihuVue,
    moblieVue
  },
}
</script>

<style>
body {
  margin-right: 15px;
  overflow-y: scroll;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
