<template>
  <div class="bodyBox">
    <van-cell>
      <van-cell is-link title="选择字体" @click="fontShow = true" :value="selectFont" />
      <van-action-sheet v-model="fontShow" clearable :actions="options" @select="fontChange" close-on-click-action />
      <van-cell title="字体颜色" value="">
        <template #right-icon>
          <el-color-picker v-model="color" @change="colorChange" size="mini"></el-color-picker>
        </template>
      </van-cell>
      <van-cell title="背景颜色" value="">
        <template #right-icon>
          <el-color-picker v-model="bgColor" @change="bgChange" size="mini"></el-color-picker>
        </template>
      </van-cell>
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item title="链接转换" name="1">
          <van-notice-bar wrapable :scrollable="false" text="tips：原链接粘贴地址->点击转换->选择复制/选择跳转" />
          <van-cell-group>
            <van-field v-model="linkTo" label="原链接" clearable placeholder="请粘贴文章至此处" />
            <van-field v-model="linkToNews" label="新链接" clearable placeholder="转换后可复制文字链接" />
          </van-cell-group>
          <div style="display: flex; justify-content: center;">
            <van-button type="default" @click="linkToNew">转换</van-button>
            <div style="margin: 0 15px;"></div>
            <van-button type="default" @click="jump">跳转</van-button>
          </div>
        </van-collapse-item>
        <van-collapse-item title="文章内容" name="2">
          <el-input v-model="inputText" placeholder="请粘贴文章至此处" type='textarea' @blur="textChange" :rows="18"
            maxlength="5000" show-word-limit />
        </van-collapse-item>
      </van-collapse>
    </van-cell>
    <div style="margin-top: 10px; display: flex; justify-content: space-evenly;">
      <van-button type="default" @click="drawGs">一键换行</van-button>
      <van-button type="default" @click="drawText" :loading="isloadding" loading-text="生成中...">一键生成图片</van-button>

      <van-button type="default" @click="downImg(srcList)">一键下载</van-button>

    </div>
    <div style="width:100%;text-align: center;">
      <el-image style="width: 140px; height:220px;margin: 20px 10px;" v-for="(item, index) in srcList" :key="index"
        :src="item" :preview-src-list="srcList">
      </el-image>
    </div>

    <div ref="contentToCapture" class="mainBox">
      <div v-for="item in 9" :key="item" class="box" ref='boxItem'>
        <div
          style=" box-sizing:border-box;width: 100%;height: 800px;padding: 36px; padding-left:47px;white-space: pre-line;text-align: left; font-size:31px; line-height: 75px; letter-spacing: 10px;display: flex;justify-content: center;align-items: center;"
          v-if="item == 1" ref="divItem">
          <span>{{ inputData[item - 1] }}</span>
        </div>
        <div
          style=" box-sizing:border-box;width: 100%;height: 800px;padding: 30px; padding-left:40px;white-space: pre-line;text-align: left; font-size: 23px;line-height: 50px; letter-spacing: 6px;display: flex;justify-content: center;align-items: center;"
          v-else ref="divItem">
          <span>{{ inputData[item - 1] }}</span>
        </div>
        <div ref="yema" style="width: 100%;height: 30px; display: flex; justify-content: center;  line-height: 30px;">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import html2canvas from 'html2canvas';
export default {
  name: 'zhihuVue',

  data () {
    return {
      isloadding: false,
      linkToNews: '',
      linkTo: '',
      selectFont: 'Arial',
      fontShow: false,
      activeNames: '2',
      selectColor: 'rgba(255,255,255,1)',
      color: 'rgba(255,255,255,1)',
      bgColor: 'rgba(0,0,0,1)',
      inputText: '',
      inputData: Array(9).fill(null),
      options: [{ name: '宋体' }, { name: '微软雅黑' }, { name: '黑体' }, { name: '隶书' }, { name: '仿宋' }, { name: 'Arial' }],
      shengyuData: '',
      shengyuData2: '',
      url: '',
      srcList: [
      ],
      con: 0,
      jiequ: '',  //段落截取的字数
      shengyu: '', //段落剩余字数
      duanluoNum: 0, //每张段落开始的index
      shengyuduanluo: '', //第一张后剩余段落
    };
  },
  mounted () {
  },

  methods: {
    linkToNew () {
      if (this.linkTo.length == 0) {
        Toast('左边知乎链接为空，重新输入')
        return
      }
      const lastIndex = this.linkTo.lastIndexOf('=')
      if (lastIndex != -1) {
        const data = this.linkTo.slice(lastIndex + 1)
        this.linkToNews = 'https://story.zhihu.com/blogger/next-manuscript/paid_column/' + data
      } else {
        Toast('链接错误！')
      }
    },
    jump () {
      if (this.linkTo.length == 0) {
        Toast('原链接为空，重新输入后再点击转换')
        return
      }
      if (this.linkToNews.length == 0) {
        Toast('右边转换后的链接为空，重新试试')
        return
      }
      window.open(this.linkToNews)
    },
    colorChange () {
      const div = this.$refs.divItem
      const yema = this.$refs.yema
      div.forEach(item => {
        item.style.color = this.color
      })
      yema.forEach(item => {
        item.style.color = this.color
      })
      this.textChange()
      this.$forceUpdate()
    },
    bgChange () {
      const div = this.$refs.divItem
      const yema = this.$refs.yema
      div.forEach(item => {
        item.style.backgroundColor = this.bgColor
      })
      yema.forEach(item => {
        item.style.backgroundColor = this.bgColor
      })
      this.textChange()
      this.$forceUpdate()
    },
    textChange () {
      // const divItems = this.$refs.divItem;
      const duanluo = this.inputText.split("\n");
      // console.log(duanluo, 'asjgisdfbsdbf');

      // let lineNum = 0 // 段落总行数
      let better = 0 // 超过的行
      let lineSum = 0 // 总行数
      for (let i = 0; i < duanluo.length; i++) {
        if (duanluo[i].length > 120) {
          Toast.fail('请先换行或添加合适的断句！单句长度不超过120字符！')
          return
        }
        if (lineSum <= 9) {
          lineSum = Math.ceil(duanluo[i].length / 11) + lineSum
        } else {
          better = lineSum - 9 //超过的行
          if (Math.ceil(duanluo[i - 1].length / 11) > better) {
            // 当前段落行数大于超过的行数，裁剪超过的行数
            console.log('超过');
            let chazhi = Math.ceil(duanluo[i - 1].length / 11) - better
            this.jiequ = duanluo[i - 1].slice(0, 11 * chazhi)
            this.shengyu = duanluo[i - 1].slice(11 * chazhi)
            this.inputData[0] = duanluo.slice(0, i - 1).toString().replace(/,/g, '\n') + '\n' + this.jiequ
            this.shengyuduanluo = this.shengyu + '\n' + duanluo.slice(i).toString().replace(/,/g, '\n')
          } else {
            console.log('不超过');
            this.inputData[0] = duanluo.slice(0, i - 1).toString().replace(/,/g, '\n')
            this.shengyuduanluo = duanluo.slice(i - 1).toString().replace(/,/g, '\n')
          }
          this.$forceUpdate()
          break
        }
      }
      // console.log(this.shengyuduanluo.split('\n'));
      let shengyuSum = this.shengyuduanluo.split('\n') // 剩余的总段数

      for (let z = 1; z < 9; z++) {
        let shengyuLine = 0 //剩余8张的行数
        for (let j = 0; j < shengyuSum.length; j++) {
          if (shengyuSum[j].length > 250) {
            Toast.fail('请先换行或添加合适的断句！单句长度不超过250字符！')
            return
          }
          if (shengyuLine <= 13) {
            shengyuLine = Math.ceil(shengyuSum[j].length / 16) + shengyuLine
          } else {
            better = shengyuLine - 13 //超过的行
            // console.log(better, 'better');

            if (Math.ceil(shengyuSum[j - 1].length / 16) > better) {
              // 当前段落行数大于超过的行数，裁剪超过的行数
              // console.log('超过');
              let chazhi = Math.ceil(shengyuSum[j - 1].length / 16) - better
              this.jiequ = shengyuSum[j - 1].slice(0, 16 * chazhi)
              this.shengyu = shengyuSum[j - 1].slice(16 * chazhi)
              this.inputData[z] = shengyuSum.slice(0, j - 1).toString().replace(/,/g, '\n') + '\n' + this.jiequ
              shengyuSum = (this.shengyu + '\n' + shengyuSum.slice(j).toString().replace(/,/g, '\n')).split('\n')
              // console.log(shengyuSum, 'shengyuSumshengyuSumshengyuSumshengyuSum');
            } else {
              // console.log('不超过');
              this.inputData[z] = shengyuSum.slice(0, j - 1).toString().replace(/,/g, '\n')
              shengyuSum = (shengyuSum.slice(j - 1).toString().replace(/,/g, '\n')).split('\n')
            }
            this.$forceUpdate()
            break
          }
        }
      }
    },
    async drawText () {
      this.textChange()
      this.isloadding = true;
      if (this.inputText == '') {
        Toast.fail('请填入文章！')
        return
      }
      this.srcList = []
      const contentToCapture = this.$refs.boxItem;
      for (let i = 0; i < contentToCapture.length; i++) {
        html2canvas(contentToCapture[i]).then(canvas => {
          this.imgData = canvas.toDataURL('image/png');
          this.url = this.imgData
          this.srcList.push(this.imgData)
          if (this.srcList.length == 9) {
            this.isloadding = false
            Toast.success('图片已生成');
          }
        })
      }

      this.$forceUpdate()
    },
    drawGs () {
      this.inputText = this.inputText.replace(/([：])/g, "$1\n")
      this.inputText = this.inputText.replace(/\n{2,}/g, "\n")
      this.inputText = this.inputText.replace(/^\d+\.\s/gm, " ".repeat(16) + "\n")
      this.inputText = this.inputText.replace(/(\d+)\n/g, " ".repeat(16) + "\n")
      this.textChange()
      this.$forceUpdate()
    },
    fontChange (items) {
      this.selectFont = items.name
      const div = this.$refs.divItem
      div.forEach(item => {
        item.style.fontFamily = items.name
      })
      this.textChange()
      this.$forceUpdate()
    },
    downImg (images) {
      if (this.inputText == '') {
        Toast.fail('请填入文章！')
        return
      }
      images.forEach((image, index) => {
        const blob = this.dataURLtoBlob(image);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `image_${index + 1}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    // base64转blob
    dataURLtoBlob (dataURL) {
      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
  },
};
</script>

<style scoped>
.bodyBox {
  /* background-color: #f7f8fa; */
  width: 100%;
  height: 100vh;
  text-align: left;
}

.mainBox {
  position: absolute;
  top: 0;
  left: -2000px;
}

.box {
  width: 540px;
  height: 840px;
  background-color: black;
  color: #fff;
  margin: 100px;
}

.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0;
}
</style>