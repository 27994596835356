import { render, staticRenderFns } from "./moblieVue.vue?vue&type=template&id=ab9afe34&scoped=true"
import script from "./moblieVue.vue?vue&type=script&lang=js"
export * from "./moblieVue.vue?vue&type=script&lang=js"
import style0 from "./moblieVue.vue?vue&type=style&index=0&id=ab9afe34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab9afe34",
  null
  
)

export default component.exports