<template >
  <div style="padding: 0 100px; margin: 10px;">
    <el-card class="box-card">
      <div slot="header" style="text-align: left;">
        <b>链接转换</b>
      </div>
      <el-col :span="18" style="margin: 20px 0;display: flex;align-items: center;">
        <el-input v-model="linkTo" placeholder="知乎文章链接" clearable />
        <el-button type="primary" style="margin: 0 20px;" @click="linkToNew">转换</el-button>
        <el-input v-model="linkToNews" placeholder="转换后可复制文字链接" clearable />
      </el-col>
      <el-col :span="6" style="margin: 20px 0;display: flex;align-items: center;">
        <el-button type="primary" style="margin-left: 20px;" @click="copy">复制</el-button>
        <el-button type="primary" style="margin: 0 20px;" @click="jump">跳转</el-button>
      </el-col>
    </el-card>
    <div style="margin: 15px;"></div>
    <el-card class="box-card">
      <div slot="header" style="text-align: left;">
        <b>图片生成</b>
      </div>
      <el-row :gutter="5" style="padding: 0 100px; margin: 10px;">
        <el-col :span="8" style="margin-bottom: 20px;display: flex;align-items: center;">
          <span>字体选择：</span>
          <el-select v-model="fontStyle" placeholder="请选择" clearable @change="fontChange" style="width: 150px;">
            <el-option v-for="item in options" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8" style="margin-bottom: 20px;display: flex;align-items: center;">
          <span>字体颜色：</span>
          <el-color-picker v-model="color" show-alpha @change="colorChange"></el-color-picker>
        </el-col>
        <el-col :span="8" style="margin-bottom: 20px;display: flex;align-items: center;">
          <span>背景颜色：</span>
          <el-color-picker v-model="bgColor" show-alpha @change="bgChange"></el-color-picker>
        </el-col>
        <el-col :span="24">
          <el-input v-model="inputText" @blur="textChange" placeholder="请粘贴文章到此处" type='textarea' :rows="15"
            maxlength="5000" show-word-limit />
        </el-col>
      </el-row>
      <div style="margin-top: 30px;">

        <el-button @click="drawGs">一键换行</el-button>
        <el-button @click="drawText" :loading="isloadding">一键生成图片</el-button>
        <el-button @click="downImg(srcList)">一键下载</el-button>
      </div>
      <el-image style="width: 140px; height:220px;margin: 20px 10px;" v-for="(item, index) in srcList" :key="index"
        :src="item" :preview-src-list="srcList">
      </el-image>
      <div ref="contentToCapture" class="mainBox">
        <div v-for="item in 9" :key="item" class="box" ref='boxItem'>
          <div
            style="display: flex;justify-content: center;align-items: center; box-sizing:border-box;width: 100%;height: 910px;padding: 36px; padding-left:47px;white-space: pre-line;text-align: left; font-size:38px; line-height: 75px; "
            v-if="item == 1" ref="divItem">
            <span>{{ inputData[item - 1] }}</span>
          </div>
          <div
            style="display: flex;justify-content: center;align-items: center; box-sizing:border-box;width: 100%;height: 910px;padding: 30px; padding-left:40px;white-space: pre-line;text-align: left; font-size: 28px;line-height: 58px; "
            v-else ref="divItem">
            <span>{{ inputData[item - 1] }}</span>
          </div>
          <div ref="yema" style="width: 100%;height: 50px; display: flex; justify-content: center;  line-height: 50px;">
            {{ item }}
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
  name: 'zhihuVue',

  data () {
    return {
      isloadding: false,
      activeName: '1',
      color: 'rgba(255,255,255,1)',
      bgColor: 'rgba(0,0,0,1)',
      inputText: '',
      inputData: Array(9).fill(null),
      // fontColor: 'white',
      // bgColor: 'black',
      fontStyle: '奶酪体',
      fontSize: 32,
      options: ['宋体', '微软雅黑', '黑体', '楷体', '仿宋', 'Arial', '奶酪体',],
      shengyuData: '',
      shengyuData2: '',
      url: '',
      srcList: [
      ],
      con: 0,
      linkTo: '',
      linkToNews: '',
      oneLineNum: 12, //第一张每行字数
      lastLineNum: 16, //其余张每行字数
    };
  },
  mounted () {
    // console.log(this.$refs.imgggg);

  },
  methods: {
    copy () {
      window.navigator.clipboard.writeText(this.linkToNews)
        .then(() => {
          this.$message.success('文本已复制到剪贴板');
        })
        .catch((error) => {
          this.$message.error(`复制文本失败：${error}`);
        });
    },
    linkToNew () {
      if (this.linkTo.length == 0) {
        this.$message.error('左边知乎链接为空，重新输入')
        return
      }
      const lastIndex = this.linkTo.lastIndexOf('=')
      if (lastIndex != -1) {
        const data = this.linkTo.slice(lastIndex + 1)
        this.linkToNews = 'https://story.zhihu.com/blogger/next-manuscript/paid_column/' + data

      } else {
        this.$message.error('链接错误！')
      }
    },
    jump () {
      if (this.linkTo.length == 0) {
        this.$message.error('左边知乎链接为空，重新输入后再点击转换')
        return
      }
      if (this.linkToNews.length == 0) {
        this.$message.error('右边转换后的链接为空，重新试试')
        return
      }
      window.open(this.linkToNews)
    },
    colorChange () {
      const div = this.$refs.divItem
      const yema = this.$refs.yema
      div.forEach(item => {
        item.style.color = this.color
      })
      yema.forEach(item => {
        item.style.color = this.color
      })
      this.textChange()
      this.$forceUpdate()
    },
    bgChange () {
      const div = this.$refs.divItem
      const yema = this.$refs.yema
      div.forEach(item => {
        item.style.backgroundColor = this.bgColor
      })
      yema.forEach(item => {
        item.style.backgroundColor = this.bgColor
      })
      this.textChange()
      this.$forceUpdate()
    },
    textChange () {
      // const divItems = this.$refs.divItem;
      const duanluo = this.inputText.split("\n");
      // console.log(duanluo, 'asjgisdfbsdbf');

      // let lineNum = 0 // 段落总行数
      let better = 0 // 超过的行
      let lineSum = 0 // 总行数
      for (let i = 0; i < duanluo.length; i++) {
        if (duanluo[i].length > 120) {
          this.$message.error('请先换行或添加合适的断句！单句长度不超过120字符！')
          return
        }
        if (lineSum <= 10) {
          lineSum = Math.ceil(duanluo[i].length / this.oneLineNum) + lineSum
          console.log(lineSum, 'lineSum');

        } else {
          better = lineSum - 10 //超过的行
          if (Math.ceil(duanluo[i - 1].length / this.oneLineNum) > better) {
            // 当前段落行数大于超过的行数，裁剪超过的行数
            console.log('超过');
            let chazhi = Math.ceil(duanluo[i - 1].length / this.oneLineNum) - better
            this.jiequ = duanluo[i - 1].slice(0, this.oneLineNum * chazhi)
            this.shengyu = duanluo[i - 1].slice(this.oneLineNum * chazhi)
            console.log(chazhi, this.jiequ, 'this.jiequ', this.shengyu, 'this.shengyu');

            this.inputData[0] = duanluo.slice(0, i - 1).toString().replace(/,/g, '\n') + '\n' + this.jiequ
            this.shengyuduanluo = this.shengyu + '\n' + duanluo.slice(i).toString().replace(/,/g, '\n')
          } else {
            console.log('不超过');
            this.inputData[0] = duanluo.slice(0, i - 1).toString().replace(/,/g, '\n')
            this.shengyuduanluo = duanluo.slice(i - 1).toString().replace(/,/g, '\n')
          }
          this.$forceUpdate()
          break
        }
      }
      // console.log(this.shengyuduanluo.split('\n'));
      let shengyuSum = this.shengyuduanluo.split('\n') // 剩余的总段数

      for (let z = 1; z < 9; z++) {
        let shengyuLine = 0 //剩余8张的行数
        for (let j = 0; j < shengyuSum.length; j++) {
          if (shengyuSum[j].length > 250) {
            this.$message.error('请先换行或添加合适的断句！单句长度不超过250字符！')
            return
          }
          if (shengyuLine <= 13) {
            shengyuLine = Math.ceil(shengyuSum[j].length / this.lastLineNum) + shengyuLine
          } else {
            better = shengyuLine - 13 //超过的行
            console.log(better, shengyuLine, 'better');

            if (Math.ceil(shengyuSum[j - 1].length / this.lastLineNum) > better) {
              // 当前段落行数大于超过的行数，裁剪超过的行数
              // console.log('超过');
              let chazhi = Math.ceil(shengyuSum[j - 1].length / this.lastLineNum) - better
              console.log(chazhi, 'chazhi')

              this.jiequ = shengyuSum[j - 1].slice(0, this.lastLineNum * chazhi)
              this.shengyu = shengyuSum[j - 1].slice(this.lastLineNum * chazhi)


              this.inputData[z] = shengyuSum.slice(0, j - 1).toString().replace(/,/g, '\n') + '\n' + this.jiequ
              shengyuSum = (this.shengyu + '\n' + shengyuSum.slice(j).toString().replace(/,/g, '\n')).split('\n')
            } else {
              console.log('不超过', z, shengyuSum);
              this.inputData[z] = shengyuSum.slice(0, j - 1).toString().replace(/,/g, '\n')
              shengyuSum = (shengyuSum.slice(j - 1).toString().replace(/,/g, '\n')).split('\n')
            }
            this.$forceUpdate()
            break
          }
        }
      }
    },
    // textChange () {
    //   const divItems = this.$refs.divItem;
    //   const duanluo = this.inputText.split("\n");
    //   // console.log(this.inputText, duanluo.length);
    //   // const strText = duanluo.toString().replace(/,/g, '')
    //   // let lineTotal = 0 //已经有多少行
    //   let lineNum = 0 // 段落总行数
    //   // let num = 0 // 字数
    //   let better = 0 // 超过的行
    //   // if (isFirst) {
    //   //   for (i = 0; i < duanluo.length; i++) {
    //   //     lineNum = lineNum + Math.ceil(duanluo[i].length / 11) //获取当前段落的行数
    //   //     if (lineNum > 12) {
    //   //       better = lineNum - 12 // 超过的行数
    //   //       let sliceLine = Math.ceil(duanluo[i].length / 11) - better //应该截取的行数
    //   //       let sliceData = duanluo[i].slice(0, 11 * sliceLine) // 截取的字符串
    //   //       this.shengyuData = duanluo[i].slice(11 * sliceLine) //截取剩余的字符串
    //   //       this.inputData[0] = duanluo.slice(0, i).toString().replace(/,/g, '\n') + sliceData
    //   //       better = 0
    //   //       isFirst = false
    //   //       this.$forceUpdate()
    //   //       console.log(i);
    //   //       break
    //   //     }
    //   //   }
    //   // } else {
    //   //   console.log(isFirst, lineNum, i);

    //   // }

    //   for (const key in divItems) {
    //     // console.log(key);
    //     if (key == 0) {
    //       for (let i = 0; i < duanluo.length; i++) {
    //         lineNum = lineNum + Math.ceil(duanluo[i].length / 11) //获取当前段落的行数
    //         // num = duanluo[i].length + num //总字数
    //         if (lineNum > 12) {
    //           // let oldLine = lineNum - Math.ceil(duanluo[i].length / 11) // 超过行数之前的行数
    //           better = lineNum - 12 // 超过的行数
    //           let sliceLine = Math.ceil(duanluo[i].length / 11) - better //应该截取的行数
    //           let sliceData = duanluo[i].slice(0, 11 * sliceLine) // 截取的字符串
    //           this.shengyuData = duanluo[i].slice(11 * sliceLine) //截取剩余的字符串
    //           this.inputData[0] = duanluo.slice(0, i).toString().replace(/,/g, '\n') + sliceData
    //           better = 0
    //           this.con = i
    //           this.$forceUpdate()
    //           break
    //         }
    //       }
    //     } else {
    //       let lineNums = 0 // 剩与行数
    //       let textLine = 0 //文本行数
    //       let total = 0 //总行数
    //       if (this.shengyuData != '') {
    //         if (this.shengyuData.length > 16) {
    //           lineNums = lineNums + Math.ceil(this.shengyuData.length / 16)
    //         }
    //         this.shengyuData = this.shengyuData + '\n'
    //         this.con = this.con + 1
    //         lineNums = 1
    //       }
    //       for (let i = this.con; i < duanluo.length; i++) {
    //         textLine = textLine + Math.ceil(duanluo[i].length / 16) //获取当前段落的行数
    //         total = textLine + lineNums
    //         if (total > 16 && key > 0) {
    //           better = total - 16 // 超过的行数
    //           let sliceLine1 = Math.ceil(duanluo[i].length / 16) - better //应该截取的行数
    //           let sliceData1 = duanluo[i].slice(0, 16 * sliceLine1) // 截取的字符串 TODO:截取不一定每行都是满字！
    //           this.shengyuData2 = duanluo[i].slice(16 * sliceLine1) //截取剩余的字符串
    //           console.log('截取的行数：' + sliceLine1, '截取的字符串：' + sliceData1, '剩余字符串：' + this.shengyuData2, '当前张数' + key, '上张最后一段：' + duanluo[this.con]);

    //           this.inputData[key] = (this.shengyuData ? this.shengyuData : '') + duanluo.slice(this.con, i).toString().replace(/,/g, '\n') + (sliceData1 ? sliceData1 : '')
    //           // console.log(better, 'ofgndfoingf');
    //           this.shengyuData = this.shengyuData2
    //           better = 0
    //           this.con = i
    //           this.$forceUpdate()
    //           break
    //         }
    //       }
    //       // console.log(this.con, this.shengyuData, '222');

    //     }

    //   }

    // },
    async drawText () {
      this.isloadding = true;
      if (this.inputText == '') {
        this.$message.error('请填入文章！')
        return
      }
      this.srcList = []
      const contentToCapture = this.$refs.boxItem;
      for (let i = 0; i < contentToCapture.length; i++) {
        html2canvas(contentToCapture[i]).then(canvas => {
          // console.log(canvas.toDataURL('image/png'), 'canvas');

          this.imgData = canvas.toDataURL('image/png');
          // console.log(this.imgData, 'this.imgData');
          this.url = this.imgData
          this.srcList.push(this.imgData)
          if (this.srcList.length == 9) {
            this.isloadding = false
            this.$message.success('图片已生成')
          }
        })
      }
      this.textChange()
      this.$forceUpdate()
    },
    drawGs () {
      this.inputText = this.inputText.replace(/([：])/g, "$1\n")
      this.inputText = this.inputText.replace(/\n{2,}/g, "\n")
      // this.inputText = this.inputText.replace(/^\d+\.\s/gm, " ".repeat(16) + "\n")
      // this.inputText = this.inputText.replace(/(\d+)\n/g, " ".repeat(16) + "\n")
      this.textChange()
      this.$forceUpdate()
    },
    fontChange () {
      const div = this.$refs.divItem
      div.forEach(item => {
        item.style.fontFamily = this.fontStyle
      })
      this.textChange()
      this.$forceUpdate()
    },
    downImg (images) {
      if (this.inputText == '') {
        this.$message.error('请填入文章！')
        return
      }
      images.forEach((image, index) => {
        var link = document.createElement("a");
        link.href = image;
        link.download = `image_${index + 1}.png`;
        link.click();
        // const blob = this.dataURLtoBlob(image);
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = `image_${index + 1}.png`;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      });
    },
    // base64转blob
    // dataURLtoBlob (dataURL) {
    //   const byteString = atob(dataURL.split(",")[1]);
    //   const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    //   const ab = new ArrayBuffer(byteString.length);
    //   const ia = new Uint8Array(ab);
    //   for (let i = 0; i < byteString.length; i++) {
    //     ia[i] = byteString.charCodeAt(i);
    //     console.log(i, 'i');

    //   }
    //   return new Blob([ab], { type: mimeString });
    // },
  },
};
</script>

<style scoped>
.mainBox {
  position: absolute;
  top: 0;
  left: -2000px;
}

.box {
  width: 540px;
  height: 960px;
  font-family: '奶酪体';
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #2e2e2e;
  margin: 100px;
  text-shadow: 1px 1px 1px rgba(137, 137, 137, 0.5);
}
</style>